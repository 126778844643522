function menuParser(snippetData, theme): any {
    const menuDefault = [];
    if (!snippetData || snippetData.error) {
        return menuDefault;
    }
    const lines = String(snippetData.text).split('\n'); // Split the input string into lines

    menuDefault.push(
        ...lines.map((line) => {
            const parts = line
                .replace(/["\r]/g, '')
                .split('\t')
                .map((part) => part.trim());

            if (parts.length >= 5) {
                const [linkText, linkSlug, linkColorLight, linkColorDark, linkRel, isExternal] = line
                    .replace(/["\r]/g, '')
                    .split('\t')
                    .map((part) => part.trim());
                const linkColor = theme === 'light' ? linkColorLight : linkColorDark;
                return { linkText, linkSlug, linkColor, linkRel, isExternal: Number(isExternal) };
            } else {
                // Old snippet without dark mode
                const [linkText, linkSlug, linkColor, linkRel] = line
                    .replace(/["\r]/g, '')
                    .split('\t')
                    .map((part) => part.trim());
                return { linkText, linkSlug, linkColor, linkRel };
            }
        })
    );

    return menuDefault;
}

function notificationBarParser(snippetData): any {
    const menuDefault = [];
    if (!snippetData || snippetData.error) {
        return menuDefault;
    }
    const text = String(snippetData.content);

    return text;
}

export { notificationBarParser, menuParser };
