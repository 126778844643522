import '../styles/css/globals.css';
import '../styles/scss/common-scss/_variables.scss';
import '../styles/scss/snippets/_snippets.scss';
import '../styles/scss/common-scss/sharedComponents/_sharedComponents.scss';

import { IncomingMessage } from 'http';

interface IncomingMessageWithCookies extends IncomingMessage {
    cookies: { [key: string]: string };
}
import { notificationBarParser } from '@data/menuParser';
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import Head from 'next/head';
import { useEffect } from 'react';

import MyProvider from '../context/provider';

type AppOwnProps = { menu: any; notificationBarData: any; notificationBarState: boolean; dealIDs: string[] };

export default function MyApp({
    Component,
    pageProps,
    menu,
    notificationBarData,
    notificationBarState,
    dealIDs
}: AppProps & AppOwnProps) {
    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="mobile-web-app-capable" content="yes" />

                <link rel="icon" type="image/png" href="https://assets.rabbitsreviews.com/favicon.ico" />
                <link rel="preconnect" href="https://www.googletagmanager.com" crossOrigin="anonymous" />
                <link rel="preconnect" href="https://api.rabbits.webcam" crossOrigin="anonymous" />
                <link rel="preconnect" href="https://www.google-analytics.com" crossOrigin="anonymous" />
                <link rel="preconnect" href="https://cdn.izooto.com" crossOrigin="anonymous" />
                <link rel="preconnect" href="https://assets.rabbitsreviews.com" crossOrigin="anonymous" />
                <link rel="dns-prefetch" href="https://www.googletagmanager.com" crossOrigin="" />
                <link rel="dns-prefetch" href="https://api.rabbits.webcam" crossOrigin="" />
                <link rel="dns-prefetch" href="https://www.google-analytics.com" crossOrigin="" />
                <link rel="dns-prefetch" href="https://cdn.izooto.com" crossOrigin="" />
                <link rel="dns-prefetch" href="https://assets.rabbitsreviews.com" crossOrigin="" />
                <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />

                {/* This Algolia link is to carry out the handshake immediately
                after loading the page but before any user interaction. See https://www.algolia.com/doc/guides/building-search-ui/going-further/improve-performance/react-hooks/  */}
                <link crossOrigin="anonymous" href="https://2OH78GUKE0-dsn.algolia.net" rel="preconnect" />
            </Head>
            <MyProvider
                menuData={menu}
                notificationBarData={notificationBarData}
                notificationBarState={notificationBarState}
                dealIDs={dealIDs}>
                <Component {...pageProps} />
            </MyProvider>
        </>
    );
}

MyApp.getInitialProps = async (context: AppContext): Promise<AppOwnProps & AppInitialProps> => {
    const ctx = await App.getInitialProps(context);
    const [menuData, notificationBarData, dealsData] = await Promise.all([
        fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/snippet/main-menu-items').then((res) => res.json()),
        fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/snippets/notification-bar').then((res) => res.json()),
        fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/snippet/deals-pages').then((res) => res.json())
    ]);
    const notificationBar = notificationBarParser(notificationBarData);
    const reqWithCookies = context.ctx.req as IncomingMessageWithCookies;
    const notificationBarDefault =
        reqWithCookies?.cookies?.notificationconsent_status === 'true' ||
        reqWithCookies?.cookies?.notificationconsent_status === undefined;

    //Divide the deals pages snippet into ids
    const dealsParsed = String(dealsData.text)
        .split('\r\n')
        .map((line) => line.split('\t')[0]);

    return {
        ...ctx,
        menu: menuData,
        notificationBarData: notificationBar,
        notificationBarState: notificationBarDefault,
        dealIDs: dealsParsed ? dealsParsed : []
    };
};
