import React, { useContext, useEffect, useState } from 'react';

// Creating a context for theme management
const ThemeContext = React.createContext();

// Provider component to wrap the app and manage state
export default function MyProvider({ children, menuData, notificationBarData = '', notificationBarState = false, dealIDs }) {
    const [theme, setTheme] = useState(global.window?.__theme || 'light');
    const toggleTheme = () => {
        global.window.__setPreferredTheme(theme === 'light' ? 'dark' : 'light');
    };

    // Setting up a listener for theme changes
    useEffect(() => {
        global.window.__onThemeChange = setTheme;
    }, []);

    // Providing context values to children
    return (
        <ThemeContext.Provider
            value={{
                menu: menuData,
                theme: theme,
                toggleTheme: toggleTheme,
                notificationBarData: notificationBarData,
                notificationBarState: notificationBarState,
                dealIDs: dealIDs
            }}>
            {children}
        </ThemeContext.Provider>
    );
}

// Custom hook to use the context
export const MyContext = () => useContext(ThemeContext);
